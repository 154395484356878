import axios from 'axios';
import router from '@/router';

const api = axios.create({
  // baseURL: 'http://localhost:4443', // Замініть на ваш бекенд URL
  baseURL: 'http://91.210.190.69:4443', // Замініть на ваш бекенд URL
  timeout: 5000, // Тайм-аут запитів
});

// Перехоплювач для запитів
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Перехоплювач для відповідей
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Токен недійсний або закінчився
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      router.push('/login'); // Перенаправлення на логін
    }
    return Promise.reject(error);
  }
);

export default api;
