// LoginPage.vue
<template>
  <div class="login-page">
    <h1>Login</h1>
    <form @submit.prevent="login">
      <div>
        <label for="email">Email:</label>
        <input v-model="email" type="email" id="email" required />
      </div>
      <div>
        <label for="password">Password:</label>
        <input v-model="password" type="password" id="password" required />
      </div>
      <button type="submit">Login</button>
    </form>
    <p v-if="error" class="error">{{ error }}</p>
  </div>
</template>

<script>
import api from '../api';

export default {
  name: 'LoginPage',
  data() {
    return {
      email: '',
      password: '',
      error: '',
    };
  },
  methods: {
    async login() {
      this.error = '';
      try {
        const response = await api.post('/auth/login', {
          email: this.email,
          password: this.password,
        });

        // Save token and user data to local storage
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.userData));

        // Emit event to notify parent component with user data
        this.$emit('login-success', response.data.userData);

        // Redirect to dashboard
        this.$router.push('/');
      } catch (err) {
        this.error = err.response?.data?.message || 'An error occurred during login.';
      }
    },
  },
  };
</script>


<style scoped>
.login-page {
  max-width: 400px;
  margin: 0 auto;
  padding: 1em;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
}

h1 {
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
}

div {
  margin-bottom: 1em;
}

label {
  margin-bottom: 0.5em;
  color: #333333;
}

input {
  padding: 0.5em;
  color: #333333;
  background: #e8e8e8;
  border: none;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
}

button {
  padding: 0.7em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  text-align: center;
}
</style>
