// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/views/LoginPage.vue';

const routes = [
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
  },
  {
    path: '/',
    name: 'Пристрої',
    component: () => import('@/views/DevicesPage.vue'), // Завантаження DevicesPage динамічно
    meta: { requiresAuth: true }, // Додаткові властивості
  },
  {
    path: '/chanels',
    name: 'ТГ Канали',
    component: () => import('@/views/ChanelsPage.vue'), // Завантаження ChanelsPage динамічно
    meta: { requiresAuth: true }, // Додаткові властивості
  },
  {
    path: '/:pathMatch(.*)*', // Сторінка 404
    name: 'NotFound',
    component: () => import('@/views/NotFoundPage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Навігаційна перевірка (middleware)
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('token');
  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login'); // Перенаправлення на сторінку входу
  } else {
    next();
  }
});

export default router;
