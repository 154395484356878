<template>
  <div id="app">
    <header>
      <nav>
        <div v-if="isLoggedIn" class="auth-info">
          <span class="user-name">{{ userData.fullName }} ({{ userData.email }})</span>
          <button @click="logout">Logout</button>
        </div>
      </nav>

    </header>
    <main>
      <!-- <div class="nav-links">
          <router-link to="/" v-if="isLoggedIn">Пристрої</router-link>
          <router-link to="/chanels" v-if="isLoggedIn">ТГ канали</router-link>
      </div> -->
      <router-view @login-success="onLoginSuccess" />
    </main>
  </div>
</template>

<script>
import { ref, onMounted} from 'vue';
import { useRouter } from 'vue-router';
import api from '@/api';

export default {
  name: 'App',
  setup() {
  const router = useRouter();
  const isLoggedIn = ref(false);
  const userData = ref({ fullName: '', email: '' });

  const checkAuth = async () => {
    try {
      const response = await api.get('/auth/me');
      isLoggedIn.value = true;
      userData.value = response.data.userData;
    } catch (err) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      isLoggedIn.value = false;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    isLoggedIn.value = false;
    userData.value = { fullName: '', email: '' };
    router.push('/login'); // Переадресація на сторінку входу
  };

  const onLoginSuccess = (user) => {
    isLoggedIn.value = true;
    userData.value = user;
  };

  onMounted(() => {
    checkAuth();
  });

  return {
    isLoggedIn,
    userData,
    logout,
    onLoginSuccess,
  };
},
};
</script>

<style scoped>
#app {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1em;
}

header {
  background-color: #007bff;
  color: white;
  padding: 1em;
  text-align: center;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1em; /* Додатковий відступ між посиланнями */
}

.auth-info {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.user-name {
  margin-right: 1em;
}

nav a {
  /* margin: 0 1em; */
  color: white;
  text-decoration: none;
  font-weight: bold;
}

nav a:first-child {
  margin-left: auto;
}

nav a:hover {
  text-decoration: underline;
}

button {
  background-color: white;
  color: #007bff;
  border: none;
  border-radius: 3px;
  padding: 0.5em 1em;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
  color: white;
}

main {
  padding: 1em;
}
</style>
